/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Link } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { TextCurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { formatPriceWithLocale } from 'helpers/formatPriceWithCommas';
import React, { useState } from 'react';
import { Icon } from 'reducers/componentResources';
import {
    CardAccountLink,
    CryptoAccountLink,
    FiatAccountLink,
    MergedAccountLink,
    SideMenuLink,
} from './SideMenu';

//icons
import { useBasePath } from 'helpers/useBasePath';
import { AssetCodeFormatter } from 'components/AssetCodeFormatter';
import { CardIcon } from 'assets/CardIcon';

type AccountListProps = {
    link: SideMenuLink;
    isLinkActive: (link: string) => boolean;
    cultureCode: string | null;
    icons?: Icon[] | null;
};
export const AccountList: React.FC<AccountListProps> = ({
    link,
    isLinkActive,
    cultureCode,
    icons,
}) => {
    const [accountsExpanded, setAccountsExpanded] = useState(false);
    const basePath = useBasePath();
    const theme = useTheme();

    const accountLinksCss = css`
        .ExpandButton {
            color: ${theme.colors.first};
        }
        .ExpandButton.Expanded {
            color: ${theme.colors.second};
        }
        .SideMenuLink .AccountValue {
            color: ${theme.colors.first};
        }
        .SideMenuLink.Active .AccountValue {
            color: ${theme.colors.second};
        }
        .SideMenuLink .NameOnCard {
            color: ${theme.colors.first};
        }
        .SideMenuLink.Active .NameOnCard {
            color: ${theme.colors.second};
        }
    `;

    const mapFn = (subLink: MergedAccountLink, index: number) => {
        const linkHasChildren = !!subLink.childElements;
        const finalUrlSegment = `${
            linkHasChildren
                ? `${subLink.childElements[0].path}`
                : DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER
        }`;
        const isActive = isLinkActive(basePath + `/${finalUrlSegment}`);
        const linkClass = `SideMenuLink ${isActive && 'Active'}`;

        if (isAccountLink(subLink)) {
            return (
                <Link className={linkClass} to={`${basePath}/${finalUrlSegment}`} key={index}>
                    <TextCurrencyIcon currencySymbol={subLink.currencySymbol} active={isActive} />
                    <div className="AccountDetails">
                        <h5 className="AccountName">{subLink.accountName ?? ''}</h5>
                        <h5 className="AccountValue">{`${
                            subLink.currencySymbol ?? ''
                        } ${formatPriceWithLocale(subLink.balance, cultureCode ?? 'en-GB')}`}</h5>
                    </div>
                </Link>
            );
        }

        if (isCardLink(subLink)) {
            return (
                <Link className={linkClass} to={`${basePath}/${finalUrlSegment}`} key={index}>
                    <CardIcon />
                    {/* <img src={`${imagesPath}/IconCard.svg`} className="CardImg" alt="Card" /> */}
                    <div className="AccountDetails">
                        <h5 className="AccountName">{subLink.accountName}</h5>
                        <h5 className="NameOnCard">{subLink.nameOnCard}</h5>
                        <p className="CardNumber">{subLink.cardNumber}</p>
                    </div>
                </Link>
            );
        }

        if (isFiatLink(subLink)) {
            return (
                <Link className={linkClass} to={`${basePath}/${finalUrlSegment}`} key={index}>
                    <TextCurrencyIcon currencySymbol={subLink.currencySymbol} active={isActive} />
                    <div className="AccountDetails">
                        <h5 className="BigAccountName">{subLink.accountName ?? ''}</h5>
                        <div className="AccountNumber">{`${subLink.routingNumber ?? ''}${
                            subLink.routingNumber && subLink.accountNumber ? ' - ' : ''
                        }${subLink.accountNumber ?? ''}`}</div>
                    </div>
                </Link>
            );
        }
        const typedLink = parseAsFiatAccountLink(subLink);

        return (
            <Link className={linkClass} to={`${basePath}/${finalUrlSegment}`} key={index}>
                <TextCurrencyIcon currencySymbol={typedLink.currencySymbol} active={isActive} />
                <div className="AccountDetails">
                    <h5 className="AccountName">{typedLink.accountName ?? ''}</h5>
                    <h5 className="AccountValue">{`${
                        typedLink.currencySymbol ?? ''
                    } ${formatPriceWithLocale(subLink.balance, cultureCode ?? 'en-GB')}`}</h5>
                    <div className="AccountNumber">{`${typedLink.routingNumber ?? ''}${
                        typedLink.routingNumber && typedLink.accountNumber ? ' - ' : ''
                    }${typedLink.accountNumber ?? ''}`}</div>
                </div>
            </Link>
        );
    };

    return (
        <>
            {link.accounts && link.accounts.length > 4 ? (
                <div css={accountLinksCss}>
                    {link.accounts.slice(0, accountsExpanded ? undefined : 3).map(mapFn)}
                    <div
                        className={`SideMenuLink ExpandButton ${
                            accountsExpanded ? 'Expanded' : ''
                        }`}
                        onClick={() => setAccountsExpanded((current) => !current)}
                    >
                        {accountsExpanded ? 'Less...' : '...'}
                    </div>
                </div>
            ) : (
                link.accounts?.map(mapFn)
            )}
        </>
    );
};

export const isFiatLink = (link: MergedAccountLink): link is FiatAccountLink => {
    return (link as FiatAccountLink).currencySymbol !== undefined;
};
export const isAccountLink = (link: MergedAccountLink): link is FiatAccountLink => {
    return link.elementType === 'Accounts';
};
export const isCardLink = (link: MergedAccountLink): link is CardAccountLink => {
    return (link as CardAccountLink).cardNumber !== undefined;
};
export const isCryptoLink = (link: MergedAccountLink): link is CryptoAccountLink => {
    return (link as CryptoAccountLink).code !== undefined;
};

const parseAsFiatAccountLink = (link: CryptoAccountLink): FiatAccountLink => {
    const { id, balance, availableBalance, code, symbol, childElements } = link;
    return {
        id,
        balance,
        availableBalance,
        childElements,
        currencySymbol: symbol,
        currencyCode: code,
        accountName: (
            <>
                <AssetCodeFormatter assetCode={code} /> account
            </>
        ),
        accountNumber: '',
        routingNumber: '',
        currencyName: code,
    };
};
