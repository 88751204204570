import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import ui from './ui';
import infoRequest from './infoRequest';
import payee from './payee';
import auth, { authEpic } from './auth';
import language, { languageEpic } from './language';
import modal from 'reducers/modal';
import componentResources, { componentResourcesEpic } from './componentResources';
import appSettings, { appSettingsEpic } from './appSettings';
import notificationUIUpdate from 'components/notifications/notificationUIUpdateReducer';
import cryptoPrices, { cryptoPricesEpic } from './cryptoPrices';
import verification, { verificationEpic } from './verification';
import errorCodes from './errorCodes';
import currencySymbols from './currencySymbols';
import utility from './utility';

export const rootReducer = combineReducers({
    ui,
    auth,
    language,
    modal,
    componentResources,
    notificationUIUpdate,
    cryptoPrices,
    verification,
    currencySymbols,
    infoRequest,
    payee,
    appSettings,
    errorCodes,
    utility,
});

export const rootEpic = combineEpics(
    authEpic,
    componentResourcesEpic,
    verificationEpic,
    languageEpic,
    cryptoPricesEpic,
    appSettingsEpic
);

export type Store = ReturnType<typeof rootReducer>;

// Global selectors (selecting data from multiple reducers)
export const selectIsPublicResourcesFetched = (store: Store) => {
    return !!store.componentResources.footerLinks && !!store.componentResources.languageList;
};
export const selectIsCustomerResourcesFetched = (store: Store) => {
    return (
        !!store.componentResources.customerMenuLinks &&
        !!store.componentResources.userInfo &&
        !!store.componentResources.customerMenuIcons &&
        !!store.verification.currentStage &&
        !store.auth.accessTokenNeedsRefresh
    );
};
