import { TableInfo, useTable } from 'api';
import { FlexTable } from 'components/flexTable';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import { SearchBox } from 'helpers/searchBox/SearchBox';
import { plaintext } from 'plaintext.config';
import React, { useEffect, useState } from 'react';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import Pagination from 'components/pagination/Pagination';
import Button from 'components/button/Button';
import { MoveMoneySubPage } from './MoveMoneySubPage';
import { NameInitialsCell } from 'components/flexTable/CustomCells';
import { InternalTransfer } from './InternalTransfer';
import { PayeePanel } from './PayeePanel/PayeePanel';
import styles from './PayeePanel/PayeePanel.module.scss';
import { useWindowDimensions } from '../../helpers/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, openModal } from '../../reducers/modal';
import { selectPayeeSubpage, updatePayeeSubpage } from '../../reducers/payee';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';
import { EditPayee, PayeeType } from './Payees/EditPayee';
import { MakePayment, useFetchPayeeCountries } from './Payees/MakePayment/MakePayment';
import { useGetFiatAccountDetails } from 'helpers/useGetFiatAccountDetails';
import { selectComponentResources } from '../../reducers/componentResources';
import { Recipient } from './Payees/MakePayment/Recipient';
import { BulkTransfer } from './BulkTransfer/BulkTransfer';
import { useFetchBulkConfig } from './BulkTransfer/helpers';

export type Props = {
    accountNumber: string;
};
export enum MoveMoneySubPages {
    PAYEES_TABLE,
    NEW_PAYEE,
    SAVED_PAYEE,
    INTERNAL_TRANSFER,
    EDIT_PAYEE,
    BULK_TRANSFER,
}
export type SavedPayee = {
    payees__Id: number;
    payees__Name: string;
    payees__AccountNumber: string;
    payees__Type: PayeeType;
    countries__CountryName: string;

    payees__AccountName: string;
    payees__AchAccountNumber: string;
    payees__PayeesReference: string;
    payees__AllowedTransferTypes: string;
    assets__Code: string;

    payees__bPullAvailable: boolean;
    payees__bInternational: boolean;
    payees__bSuppressed: boolean;

    addressCountries__CountryName: string | null;
};

const idColumn: keyof SavedPayee = 'payees__Id';

export const payeeRowFormatter = (row: SavedPayee) => {
    const formattedRow: { [k in keyof typeof row]: React.ReactNode } = { ...row };
    if (formattedRow.payees__Name) {
        formattedRow.payees__Name = <NameInitialsCell name={row.payees__Name} />;
    }
    return formattedRow;
};

export const MoveMoney: React.FC<Props> = ({ accountNumber }) => {
    const accountDetails = useGetFiatAccountDetails();

    const { userInfo } = useSelector(selectComponentResources);
    const requiresApprovalForPayee = userInfo?.bRequiresApprovalToCreatePayee;
    const canBulkTransfer = userInfo?.bCanBulkTransfer;

    const {
        payeeSubPage: currentPage,
        payee: selectedPayee,
        refreshTable,
    } = useSelector(selectPayeeSubpage);
    const dispatch = useDispatch();

    const { availableCountries, errorCountries } = useFetchPayeeCountries();

    const { accounts } = useFetchBulkConfig();

    const setCurrentPage = (page: MoveMoneySubPages, payee?: SavedPayee) => {
        dispatch(
            updatePayeeSubpage({
                page,
                payee: payee ?? undefined,
            })
        );
    };

    useEffect(() => {
        return () => {
            setCurrentPage(MoveMoneySubPages.PAYEES_TABLE);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const payeeTable = useTable<SavedPayee, any>({
        url: endpoints.accounts.getPayeeList,
        bClearFilters: true,
        filters: accountDetails?.currencyCode
            ? `assets__Code EQ (${accountDetails?.currencyCode})`
            : '',
        params: { customerAssetAccountsId: accountDetails?.id },
    });
    const { loading, data, reload } = payeeTable;

    const [selectedRow, setSelectedRow] = useState<number>();

    const { width } = useWindowDimensions();

    useAfterMountEffect(() => {
        reload();
    }, [refreshTable]);

    const resetState = () => {
        setCurrentPage(MoveMoneySubPages.PAYEES_TABLE);
    };
    const handlePayeeChange = () => {
        resetState();
        reload();
    };

    const onRowClick = (id: number) => {
        if (selectedRow === id) setSelectedRow(undefined);
        else setSelectedRow(id);
    };
    useEffect(() => {
        if (width >= 1400 || !selectedRow) return;
        else
            dispatch(
                openModal({
                    modalType: ModalTypes.PAYEE_DETAILS,
                    data: { rowData: selectedData! },
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, width]);

    const selectedData = data?.details.listData.find((entry) => entry[idColumn] === selectedRow);

    if (loading) {
        return <SubPageLoader message={plaintext.accounts.loading} />;
    }

    const emptyTable = data?.details.listData.length === 0;
    if (currentPage === MoveMoneySubPages.PAYEES_TABLE)
        return (
            <div className={`MoveMoneyPage ${emptyTable ? 'Empty' : ''}`}>
                <div className="TableHeader">
                    <div className="TransferButtons">
                        <Button
                            priority="primary"
                            onClick={() => setCurrentPage(MoveMoneySubPages.NEW_PAYEE)}
                        >
                            {requiresApprovalForPayee ? 'Add Payee' : 'Pay New Payee'}
                        </Button>
                        {canBulkTransfer &&
                            !!accounts.find((acc) => acc?.id === accountDetails?.id) && (
                                <Button
                                    priority="primary"
                                    onClick={() => setCurrentPage(MoveMoneySubPages.BULK_TRANSFER)}
                                >
                                    Bulk transfer
                                </Button>
                            )}
                    </div>
                    <SearchBox
                        key={'searchBox'}
                        placeholder={plaintext.accounts.searchPayees}
                        initialSearchString={data?.details.searchString ?? ''}
                        changeSearch={data?.actions.changeSearch}
                        goToPage={data?.actions.goToPage}
                    />
                </div>
                <div className="TableWrapper">
                    <FlexTable
                        idColumn={idColumn}
                        table={payeeTable as TableInfo}
                        rowFormatter={payeeRowFormatter}
                        onRowClick={onRowClick}
                        rowCellOptions={{
                            _button: {
                                style: {
                                    minWidth: '150px',
                                    width: '150px',
                                    flex: '0',
                                    padding: '0 10 0 0',
                                },
                            },
                        }}
                        rowButton={(row: SavedPayee) => (
                            <div className="MoveMoneyButtons">
                                <Button
                                    priority="primary"
                                    color="second"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCurrentPage(MoveMoneySubPages.SAVED_PAYEE, row);
                                    }}
                                    disabled={row.payees__bSuppressed}
                                >
                                    {row.payees__bSuppressed ? 'Awaiting Approval' : 'Make Payment'}
                                </Button>
                            </div>
                        )}
                    />
                    {!emptyTable && (
                        <div className={styles.TableEntryInfoPanelWrapper}>
                            <PayeePanel
                                selectedRowData={selectedData}
                                closePanel={() => setSelectedRow(undefined)}
                            />
                        </div>
                    )}
                </div>
                {emptyTable && !loading && (
                    <div className="EmptyTable">
                        <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                        <h3 className="Message">{plaintext.tables.emptyPayeesTable}</h3>
                    </div>
                )}
                <Pagination table={payeeTable} />
            </div>
        );
    if (currentPage === MoveMoneySubPages.BULK_TRANSFER)
        return <BulkTransfer back={resetState} accountId={accountDetails?.id} />;
    if (currentPage === MoveMoneySubPages.NEW_PAYEE && !requiresApprovalForPayee)
        return <MakePayment goBack={resetState} />;
    if (currentPage === MoveMoneySubPages.NEW_PAYEE && requiresApprovalForPayee)
        return (
            <MoveMoneySubPage back={resetState}>
                <Recipient
                    availableCountries={availableCountries}
                    errorCountries={errorCountries}
                    initialValues={{}}
                    hideSaveOption
                    nextStep={handlePayeeChange}
                    btntext="Save"
                />
            </MoveMoneySubPage>
        );
    if (currentPage === MoveMoneySubPages.SAVED_PAYEE)
        return (
            <MakePayment
                goBack={resetState}
                payee={
                    selectedPayee
                        ? {
                              id: selectedPayee?.payees__Id,
                              name: selectedPayee?.payees__Name,
                              allowedTransferTypes: selectedPayee?.payees__AllowedTransferTypes,
                              bInternational: selectedPayee?.payees__bInternational,
                              accountType: selectedPayee.payees__AccountName,

                              countryCode: selectedPayee?.countries__CountryName,
                              accountName: selectedPayee.payees__AccountName,
                              payeesReference: selectedPayee.payees__PayeesReference,
                              accountNumber: selectedPayee.payees__AccountNumber,
                              payeeType: selectedPayee.payees__Type,
                              addressCountryCode: selectedPayee.addressCountries__CountryName ?? '',
                              //   Check these
                              routingNumber: selectedPayee.payees__AchAccountNumber,
                              swiftNumber: selectedPayee.payees__AchAccountNumber,
                              iban: selectedPayee.payees__AchAccountNumber,
                          }
                        : undefined
                }
            />
        );
    if (currentPage === MoveMoneySubPages.EDIT_PAYEE)
        return (
            <MoveMoneySubPage back={resetState}>
                <EditPayee
                    onBack={() => {
                        setCurrentPage(MoveMoneySubPages.PAYEES_TABLE);
                        reload();
                    }}
                />
            </MoveMoneySubPage>
        );
    if (currentPage === MoveMoneySubPages.INTERNAL_TRANSFER)
        return (
            <MoveMoneySubPage back={() => setCurrentPage(MoveMoneySubPages.PAYEES_TABLE)}>
                <InternalTransfer
                    fromCustomerAssetAccountsId={parseInt(accountNumber)}
                    onSuccess={resetState}
                />
            </MoveMoneySubPage>
        );
    return null;
};
