import { useField } from 'formik';
import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { ErrorM } from './ErrorM';
import { parseISO } from 'date-fns';
import classNames from 'classnames';
import { FormFieldTooltip, TooltipIconData } from './FormFieldTooltip';

type Props = {
    field: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    error?: boolean;
    datePickerProps?: Partial<ReactDatePickerProps>;
    sideBySide?: boolean;
    tooltip?: React.ReactNode;
    tooltipIconData?: TooltipIconData | null;
};

export const FormDateField = ({
    field,
    label,
    placeholder,
    required,
    error,
    datePickerProps,
    sideBySide,
    tooltip,
    tooltipIconData,
}: Props) => {
    const [formikProps, formikMeta, formikHelpers] = useField(field);

    const userLocale = useSelector(selectCultureCode) ?? 'en-US';
    return (
        <div className={classNames('FormBox FormSideBySide', { SideBySide: sideBySide })}>
            <div className="DatePickerFormLabel FormLabel">
                <label>{label}</label>
            </div>
            <div className={classNames('DatePickerFormField FormField', { HasTooltip: !!tooltip })}>
                {tooltip && (
                    <FormFieldTooltip tooltip={tooltip} tooltipIconData={tooltipIconData} />
                )}
                <DatePicker
                    locale={userLocale}
                    className={`DatePickerBox DatePickerBox1 EditBox ${
                        formikMeta.error && formikMeta.touched ? 'ErrorInput' : ''
                    }`}
                    placeholderText={placeholder}
                    onBlur={(e) => {
                        formikHelpers.setTouched(true);
                    }}
                    selected={formikProps.value ? new Date(formikProps.value) : undefined}
                    onChange={(value) => formikHelpers.setValue(value)}
                    required={required}
                    {...datePickerProps}
                />
            </div>
            <ErrorM name={field} />
        </div>
    );
};
